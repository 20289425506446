<template>
  <tr class="v-data-table__mobile-table-row">
    <td class="v-data-table__mobile-row" :class="{'product-name': header.value === 'name'}" :style="{ 'padding-right': header.value === 'status' ? '0' : '16px' }" v-for="(header, i) in headers" :key="i">
      <div class="v-data-table__mobile-row__header">{{ header.text }}</div>

      <div class="v-data-table__mobile-row__cell" v-if="header.value === 'actions'">
        <div class="desktop-button center">
          <v-btn small depressed class="blue-grey lighten-5 blue-grey--text text--darken-1" @click="openProductEditModal">
            {{ $t('Edit') }}</v-btn>
        </div>
        <div class="mobile-button center">
          <v-icon @click="openProductEditModal" color="blue">mdi-pencil</v-icon>
        </div>
      </div>
      <div class="status-product" v-else-if="header.value === 'status'">
        <v-switch v-model="status" :loading="statusLoading" @change="updateStatus" class="status-switch" color="orange" inset flat hide-details />
      </div>
      <div v-else-if="header.value === 'price'">
        <div class="item-clickable center">
          <span class="font-weight-medium">{{ Math.abs(item.price).toFixed(2) }}</span>
        </div>
      </div>
      <div v-else-if="header.value === 'sort'">
        <div class="item-clickable center" v-if="!editSort && showSort" @click="editSort = true">
          <span class="font-weight-medium">{{ position }}</span>
        </div>
        <div class="item-clickable center" v-else>
          <v-text-field v-model="position" class="column-edit" @click:append="editSort = false" dense :loading="sortLoading"
                        append-icon="mdi-arrow-u-left-bottom" append-outer-icon="mdi-check" @click:append-outer="updateCategoryPosition" @keyup.enter="updateCategoryPosition"/>
        </div>
      </div>
      <div class="v-data-table__mobile-row__cell" v-else>{{ item[header.value] }}</div>
    </td>
  </tr>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProductListTableLineMobile',
  props: {
    item: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    categoryId: {
      type: Number,
      default: 0
    },
    showSort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editPrice: false,
      newPrice: null,
      priceLoading: false,
      status: 0,
      position: 0,
      statusLoading: false,
      editSort: false,
      sortLoading: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      updateProduct: 'catalog/product/update',
      updateCategory: 'catalog/category/save'
    }),
    updatePrice () {
      this.priceLoading = true
      this.updateProduct({ token: this.token, product_id: this.item.entity_id, product: { price: this.newPrice } })
        .then(result => {
          this.editPrice = false
          this.$bus.$emit('catalog-product-update', { product_id: this.item.entity_id, product: result.product })
        })
        .finally(() => {
          this.priceLoading = false
        })
    },
    updateStatus () {
      this.statusLoading = true
      this.updateProduct({ token: this.token, product_id: this.item.entity_id, product: { status: this.status } })
        .then(result => {
          this.$bus.$emit('catalog-product-update', { product_id: this.item.entity_id, product: result.product })
        })
        .finally(() => {
          this.statusLoading = false
        })
    },
    updateCategoryPosition () {
      this.sortLoading = true
      let category = {products_positions: {}}
      category.products_positions[this.item.entity_id] = Number(this.position)
      this.updateCategory({ token: this.token, category_id: this.categoryId, category })
        .then(() => this.editSort = false)
        .finally(() => {
          this.sortLoading = false
        })
    },
    openPriceEdit () {
      this.newPrice = Math.abs(this.item.price).toFixed(2)
      this.editPrice = true
    },
    openProductEditModal () {
      this.$bus.$emit('catalog-product-edit', this.item.entity_id)
    }
  },
  mounted () {
    this.status = this.item.status === 2 ? 0 : this.item.status
    this.position = this.item.position ? this.item.position : 0
  },
  watch: {
    'item.status' () {
      this.status = this.item.status === 2 ? 0 : this.item.status
    }
  }
}
</script>
<style lang="scss" scoped>
.item-clickable {
  justify-content: flex-end;
  margin: 0;
  cursor: pointer;
}

.product-line {
  height: 45px;
}

.product-line:hover {
  background: #eeeeee;
  cursor: pointer;
}

.column-edit {
  max-width: 135px;
}

.status-switch {
  margin: 0;
  padding: 0;
}
.desktop-button {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}
.mobile-button {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}
.product-name {
  @media (max-width: 600px) {
    width: 30%;
  }
}
.status-product {
  @media (max-width: 600px) {
    width: 17%;
    padding-left: 5px;
  }
}
.center {
  text-align: center;
}

.v-data-table__mobile-row {
  &.product-name {
    width: 100%;
    align-items: flex-start;
    padding-top: 16px;
  }
}

.v-data-table__mobile-row__cell {
  max-width: 75%;
}
</style>
